import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject, Renderer2 } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { AssetGroupDetailService } from '@asset-detail/services/asset-group-details.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { AssetGroupDetail, AssetCategotyDetail } from '@asset-detail/model/asset-group-detail.model';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { AssetGroupListing } from '@asset-detail/model/asset-group-listing.model';
import { VideoPageService } from '@video-page/services/videopage.service';
import { VideoPage, CastCrews, VideoPageData } from '@video-page/model/video-page';
import { HeaderService } from '@header/services/header.service';
import { environment } from '@environments/environment';
import { CommonService } from 'src/app/shared';
import * as moment from 'moment';

declare var $: any;
declare const bitmovin: any;
@Component({
  selector: 'app-asset-group-details',
  templateUrl: '../view/asset-group-details.component.html',
  styleUrls: ['../css/asset-group-details.component.css']
})
export class AssetGroupDetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  commonService: CommonService;
  assetGroupService: AssetGroupDetailService;
  videoService: VideoPageService;
  assetGroupData: AssetGroupDetail;
  assetCategoryData: AssetCategotyDetail;
  assetGroupList: AssetGroupListing;
  skip: number;
  assetPath: string;
  videoPageData: VideoPageData;
  playerObject: any;
  videoURL: string;
  posterImage: string;
  videoTitle: string;
  videoDescription: string;
  director: string;
  cast: string;
  castCrewData: CastCrews;
  viewMore: boolean;
  watchListTimeBitmovin: number = 5;
  addedToWatchList: any = 0;
  bookmark_duration: string;
  loading: boolean;
  mainloading = true;
  headerService: HeaderService;
  trailerPlayBackUrl: string;
  isShowVideo: boolean = false;
  watchMovie: boolean = false;
  setBookMark: boolean = false;
  showVideoError: boolean = false;
  isShowImage: boolean = false;
  same_for_all_geo_zone: boolean = false;
  geoStatus: boolean = false;
  user_can_see_asset: boolean = false;
  isIncreaseCount: boolean = false;
  showButton: boolean;
  playTimeOut: any;
  bitMovieUnsubsribe: any;
  noGenre: boolean;
  isUserLikes: any;
  videoPath: any;
  seasonPath: any = 0;
  currentIndex = 0;
  seconds = 5;
  videoFinish: boolean;
  replay: any;
  secondInterval: any;
  secondsTimeout: any;
  clickOntrailer = false;

  constructor(activateRoute: ActivatedRoute,
    private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.assetGroupService = ServiceLocator.injector.get(AssetGroupDetailService);
    this.videoService = ServiceLocator.injector.get(VideoPageService);
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.commonService = ServiceLocator.injector.get(CommonService);
    this.route = activateRoute;
  }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    window.onscroll = this.adjustPlayer;
    this.mainloading = true;
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    this.videoPageData = null;
    this.route.params.subscribe((params: Params) => {
      this.videoFinish = false;
      this.clickOntrailer = false;
      window.scroll(0, 0);
      this.currentIndex = 0;
      this.assetPath = params['path'];
      this.getAssetGroupDetails(this.assetPath);
      window.scroll(0, 0);
    });

  }

  seekPlayer(duration, videoDuration: boolean, setBookMark: boolean) {
    if (videoDuration) {
      if (setBookMark) {
        this.playerObject.seek(this.playerObject.getCurrentTime() + this.bookmark_duration);
      }
    } else {
      this.playerObject.seek(this.playerObject.getCurrentTime() + duration);
    }
  }

  public getAssetGroupDetails(path: string): void {

    const assetGroupDetailRequest = {
      "path": path
    }
    let genreFiterString = "?filter=" + encodeURI(JSON.stringify(assetGroupDetailRequest));
    this.assetGroupService.getAssetGroupDetails(genreFiterString).subscribe(res => {
      if (res !== undefined && res.success == true) {
        this.assetGroupData = res;
        console.log('assetGroupData', this.assetGroupData)
        this.getAssetCategoryDetails(this.assetGroupData.data[0].assetCategory[0].path, true);
      }
    },
      err => {
        console.log(err);
      });
  }

  public getAssetGroupListing(skip: number, assetCategory: AssetCategotyDetail, setData: boolean, startVideo): void {
    this.clickOntrailer = false;
    const assetGroupListingRequest = {
      "path": assetCategory.path,
      "type": "asset_category",
      "deviceTypeId": "1"
    }
    this.skip = this.skip + skip;
    if (setData) {
      this.loading = true;
    }
    let assetGroupListingString = "?filter=" + encodeURI(JSON.stringify(assetGroupListingRequest)) + "&start=" + this.skip + "&limit=24";
    this.assetGroupService.getAssetListing(assetGroupListingString).subscribe(res => {
      if (res !== undefined && res.success === true) {
        console.log('res', res.data);
        res.data.map((req) => {
          let watchRequest = {
            userId: this.getUserId(),
            assetId: req.path,
            currentDate: moment.utc().utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss')
          }
          this.videoService.watchedWatchlist(watchRequest).subscribe(res => {
            if (res !== undefined && res.success == true) {
              console.log(req.path, 'result', res);
            }
          });
        })
        if (res.data.length == 24) {
          this.viewMore = true;
        } else {
          this.viewMore = false;
        }
        // let show = false;
        // res.data.find(function (post) {
        //   if (new Date(post.airStartDate) > new Date())
        //     return show = true;
        // });
        // console.log('show', show)
        // if (show) {
        //   res.data.sort((a, b) => b.airStartDate > a.airStartDate ? -1 : 1);
        // } else {
        //   res.data.sort((a, b) => b.airStartDate > a.airStartDate ? 1 : -1);
        // }
        // console.log('res', res.data)

        if (setData) {
          res.data.forEach(record => {
            this.assetGroupList.data.push(record);
          });
          this.loading = false;

        } else {
          this.assetGroupList = res;
          if (startVideo != false) {
            this.getVideoDetails(this.assetGroupList.data[0].path, this.currentIndex);
            this.loading = false;
          }
        }
      }
    },
      err => {
        console.log(err);
      });
  }

  public getAssetCategoryDetails(assetPath: string, startvideo): void {
    this.currentIndex = 0;
    this.seconds = 5;
    this.assetGroupList = undefined;
    this.clickOntrailer = false;
    // this.playerObject = undefined;
    // this.mainloading = true;
    // window.scroll(0,0);
    this.seasonPath = assetPath;
    const assetCategoryDetailRequest = {
      "path": assetPath
    }
    let assetCategoryFilterString = "?filter=" + encodeURI(JSON.stringify(assetCategoryDetailRequest));
    this.assetGroupService.getAssetCategoty(assetCategoryFilterString).subscribe(res => {
      if (res !== undefined && res.success === true) {
        this.assetCategoryData = res.data[0];
        this.skip = 0;
        this.getAssetGroupListing(0, this.assetCategoryData, false, startvideo);
      }
    },
      err => {
        console.log(err);
      });
  }

  public gotoVideoPage(pagedata): void {
    let currentDate = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss'));
    let localStartDates = moment.utc(pagedata.airStartDate).format('YYYY-MM-DD HH:mm:ss');
    console.log('localStartDate', moment(currentDate).isAfter(localStartDates))
    if (moment(currentDate).isAfter(localStartDates) || pagedata.airStartDate == '0000-00-00 00:00:00'|| localStartDates == "Invalid date" || pagedata.contentType=='Live') {
      this.router.navigate([
        "/video",
        pagedata.title.split(/\s/).join(""),
        pagedata.path,
      ]);
    } else {
      return;
    }
  }

  // public gotoVideoPage(pagedata): void {
  //   this.router.navigate([
  //     "/video",
  //     pagedata.title.split(/\s/).join(""),
  //     pagedata.path,
  //   ]);
  // }

  public getVideoDetails(videoPath: string, currenIndex): void {
    this.clickOntrailer = false;
    this.currentIndex = currenIndex;
    this.videoFinish = false;
    this.seconds = 5;
    this.videoPath = videoPath.toString();
    this.getCastCrew(this.videoPath);
    // this.mainloading = true;
    if (this.playerObject != undefined) {
      this.playerObject.destroy();
      this.playerObject = undefined;
    }

    const assetDetailRequest = { "deviceTypeId": "1", "userId": this.getUserId() };
    const assetDetailFiterString = "?filter=" + encodeURI(JSON.stringify(assetDetailRequest));
    this.videoService.getAssetDetails(this.videoPath, assetDetailFiterString).subscribe(res => {
      if (res !== undefined && res.success === true) {
        this.videoPageData = res.data[0];
        this.addedToWatchList = this.videoPageData.isUserWatched;
        this.videoURL = this.videoPageData.playbackUrl;
        this.posterImage = this.videoPageData.horizontalFilePath;
        this.trailerPlayBackUrl = this.videoPageData.mapTrailerVideo;
        this.isUserLikes = this.videoPageData.isUserLikes;

        if (this.trailerPlayBackUrl == '') {
          this.showButton = false;
        } else if (this.trailerPlayBackUrl == null) {
          this.showButton = false;
        } else if (!this.trailerPlayBackUrl) {
          this.showButton = false;
        } else {
          this.showButton = true;
        }
        this.bookmark_duration = this.videoPageData.bookmarkDuration;
        if (this.bookmark_duration == null) {
          this.bookmark_duration = '0';
        }
        this.same_for_all_geo_zone = false;
        this.geoStatus = this.videoPageData.geoStatus;


        if ((BaseComponent.baseUser !== undefined &&
          Object.keys(BaseComponent.baseUser).length !== 0) && this.geoStatus) {
          this.watchMovie = true;
          this.isShowVideo = true;
          this.setBookMark = true;
          this.showVideoError = false;
          this.showVideo(this.videoURL, this.posterImage, true);
          this.isIncreaseCount = true;
        } else if ((BaseComponent.baseUser !== undefined &&
          Object.keys(BaseComponent.baseUser).length !== 0) && !this.geoStatus) {
          this.showVideoError = true;
          this.isShowImage = false;
          this.isShowVideo = false;
          this.watchMovie = false;
          this.playerObject = undefined;
          this.isIncreaseCount = false;
        } else if (this.geoStatus) {
          // this.showVideoError = false;
          // this.watchMovie = false;
          // this.isShowVideo = false;
          // this.isShowImage = true;
          // this.playerObject = undefined;
          this.watchMovie = true;
          this.isShowVideo = true;
          this.setBookMark = false;
          this.showVideoError = false;
          this.showVideo(this.videoURL, this.posterImage, true);
          this.isIncreaseCount = false;
        } else if (!this.geoStatus) {
          this.showVideoError = true;
          this.isShowImage = false;
          this.isShowVideo = false;
          this.setBookMark = false;
          this.watchMovie = false;
          this.playerObject = undefined;
          this.isIncreaseCount = false;
        }
        setTimeout(() => {
          window.scroll(0, 0);
        }, 30);
        // this.mainloading = false;
      }

    },
      err => {
        console.log(err);
      });
  }

  public showTrailer(): void {
    this.clickOntrailer = true;
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    this.showVideo(this.trailerPlayBackUrl, this.posterImage, true);
    this.isShowVideo = true;
    this.setBookMark = false;
    this.isShowImage = false;
    this.showVideoError = false;
    this.isIncreaseCount = false;
  }

  public showMovie(): void {
    this.videoFinish = false;
    this.clickOntrailer = false;
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    if ((BaseComponent.baseUser !== undefined &&
      Object.keys(BaseComponent.baseUser).length !== 0) && this.geoStatus) {
      this.isShowImage = false;
      this.showVideoError = false;
      this.setBookMark = true;
      this.isShowVideo = true;
      this.showVideo(this.videoURL, this.posterImage, true);
      this.isIncreaseCount = true;
    } else if ((BaseComponent.baseUser !== undefined &&
      Object.keys(BaseComponent.baseUser).length !== 0) && !this.geoStatus) {
      this.isShowVideo = false;
      this.isShowImage = false;
      this.showVideoError = true;
      this.playerObject = undefined;
      this.isIncreaseCount = true;

    } else if (this.geoStatus) {
      this.watchMovie = true;
      this.isShowVideo = true;
      this.setBookMark = false;
      this.showVideoError = false;
      this.showVideo(this.videoURL, this.posterImage, true);
      this.isIncreaseCount = true;

    } else if (!this.geoStatus) {
      this.showVideoError = true;
      this.isShowImage = false;
      this.isShowVideo = false;
      this.watchMovie = false;
      this.setBookMark = false;
      this.playerObject = undefined;
      this.isIncreaseCount = true;

    }

  }

  showVideo(videoUrl: string, posterImage: string, autoPlay: boolean): void {
    if (autoPlay && this.playerObject !== undefined) {
      this.playerObject.pause();
    }
    this.mainloading = false;
    setTimeout(() => {

      const source = {
        hls: videoUrl,
        poster: posterImage,
        // analytics: {
        //   videoId: this.assetPath,
        //   title: this.videoPageData.title
        // }
      };

      if (this.videoPageData.path) {
        var vodId = this.videoPageData.path;
      }
      if (this.videoPageData.title) {
        var vodTitle = this.videoPageData.title
      }
      const config = {
        key: 'e402008a-94c7-4288-912b-2e4ab92d5d14',
        analytics: {
          key: 'ac814c2b-40b6-4085-8d3d-073db406b5d2',
          videoId: vodId,
          title: vodTitle
        },
        events: {
          playerresized: function (ev) {
            /**
             * when the player switches from minimized to full size, update the container size to prevent the page
             * from jumping when scrolling up
             * work with a timeout as the resize event is triggered periodically and when resizing back to the original size,
             * the first few events will have a smaller size as the player is gradually growing.
             */
            if (
              !$('.player-switch').hasClass('fixed-player')
            ) {
              clearTimeout(this.resizeTimeout);
              this.resizeTimeout = setTimeout(function () {
                $('.player-container'
                ).height(ev.height.substr(0, ev.height.indexOf('px')) - 0);
              },
                250
              )
                ;
            }
          }
        }
      };

      var self = this;
      var onTimeChanged = function (data) {
        let currPlaybackTime = self.playerObject.getCurrentTime();
        addToBookmarkOnTimeUpdateJavascript();
        if (self.addedToWatchList == 0) {
          if (
            BaseComponent.baseUser !== undefined &&
            Object.keys(BaseComponent.baseUser).length !== 0
          ) {
            self.addToWatchlist();
            self.playerObject.player.off('TimeChanged', () => { });
          }
        }
      };

      var addToBookmarkOnTimeUpdateJavascript = function () {
        let currPlaybackTime = self.playerObject.getCurrentTime();
        let diffInTime = 0;
        if (parseInt(currPlaybackTime) > parseInt(self.bookmark_duration)) {
          diffInTime = parseInt(currPlaybackTime) - parseInt(self.bookmark_duration);
        } else {
          diffInTime = parseInt(self.bookmark_duration) - parseInt(currPlaybackTime);
        }
        if (diffInTime >= self.watchListTimeBitmovin) {
          addToBookmarkJavascript(currPlaybackTime);
        }
      };

      var onTimePaused = function (data) {
        let currPlaybackTime = self.playerObject.getCurrentTime();
        addToBookmarkJavascript(currPlaybackTime);
        self.playerObject.off('Paused', () => { });
      };

      var addToBookmarkJavascript = function (current_playback_position) {
        if (current_playback_position != 0) {
          if (self.setBookMark) {
            if (
              BaseComponent.baseUser !== undefined &&
              Object.keys(BaseComponent.baseUser).length !== 0
            ) {
              const pageRequest = {
                assetId: this.videoPageData[0].path,
                duration: this.playerObject.getCurrentTime
              };
              self.videoService.addToBookmark(pageRequest).subscribe(result => {
                if (result !== undefined && result.success == true) {
                  self.bookmark_duration = result.data.duration;
                }
              });
            }
          }
        }

      };

      var onVideoPlay = function (current_playback_position) {
        console.log('getFacebookPixle call');
        self.getFacebookPixle();
      };

      var onFinished = function () {
        self.seconds = 5;
        if (self.clickOntrailer == true) {
          self.videoFinish = true;
          self.replay = false;
        } else {
          self.videoFinish = true;
          self.replay = false;
          if (self.assetGroupList.data.length > 0) {
            if (self.assetGroupList.data.length != self.currentIndex + 1) {
              self.secondInterval = setInterval(() => {
                self.seconds = self.seconds - 1;
                if (self.seconds == 1) {
                  clearInterval(self.secondInterval);
                }
              }, 1000);
              self.secondsTimeout = setTimeout(() => {
                if (self.replay == false) {
                  self.videoFinish = false;
                  self.mainloading = true;
                  // self.nextVideo();
                }
              }, 5000);
            }
          }
        }
      };
      var onError = function () {
        self.seconds = 5;
        if (self.clickOntrailer == true) {
          self.videoFinish = true;
          self.replay = false;
        } else {
          self.videoFinish = true;
          self.replay = false;
          if (self.assetGroupList.data.length > 0) {
            if (self.assetGroupList.data.length != self.currentIndex + 1) {
              self.secondInterval = setInterval(() => {
                self.seconds = self.seconds - 1;
                if (self.seconds == 1) {
                  clearInterval(self.secondInterval);
                }
              }, 1000);
              self.secondsTimeout = setTimeout(() => {
                if (self.replay == false) {
                  self.videoFinish = false;
                  self.mainloading = true;
                  // self.nextVideo();
                }
              }, 5000);
            }
          }
        }
      }


      if (this.playerObject === undefined) {
        // const container = document.getElementById('bitmovin-player');
        // const player = new bitmovin.player.Player(container, config);
        // player.on(bitmovin.player.PlayerEvent.TimeChanged, onTimeChanged);
        // player.on(bitmovin.player.PlayerEvent.Paused, onTimePaused);
        // player.on(bitmovin.player.PlayerEvent.Play, onVideoPlay);
        // player.on(bitmovin.player.PlayerEvent.PlaybackFinished, onFinished);
        // player.on(bitmovin.player.PlayerEvent.Error, onError);
        // this.playerObject = player;
      }
      // this.playerObject.load(source).then(function (player) {
      //   self.seekPlayer(0, true, self.setBookMark);
      //   if (autoPlay) {
      //     setTimeout(() => {
      //       self.playerObject.play();
      //     }, 1000);
      //   }
      // }, function (reason) { });
      // this.bitMovieUnsubsribe = this.playerObject.load(source).then(function (player) {
      //   var container = $('.player-container');
      //   var playerHeight = $('#bitmovin-player').height();
      //   self.seekPlayer(0, true, self.setBookMark);
      //   if (autoPlay) {
      //     setTimeout(() => {
      //       self.playerObject.play();
      //     }, 1000);
      //   }
      //   if (container.height() <= playerHeight) {
      //     container.height(playerHeight);
      //   }
      //   self.playTimeOut = setTimeout(() => {
      //     if (self.playerObject !== undefined) {
      //       self.playerObject.play();
      //     }
      //   }, 1000);

      // }, function (reason) {
      //   // console.log(reason);
      // });

    }, 1.5);
  }

  nextVideo() {
    // for (let i = 0; i < this.assetGroupList.data.length; i++) {
    //   if (i == this.currentIndex) {
    //     const currentVideo = this.assetGroupList.data.filter((videoObj) => {
    //       return videoObj.path == this.videoPageData.path;
    //     })
    //     if(currentVideo.length > 0 && this.assetGroupList.data.length > i + 1) {
    //       this.getVideoDetails(this.assetGroupList.data[i + 1].path, i + 1);
    //     } else {
    //       this.getVideoDetails(this.assetGroupList.data[i].path, i);
    //     }
    //     break;
    //   }
    // }
  }

  replayVideo() {
    // $('#premium-id').removeClass('premium-vod');
    // alert('loglog');
    // console.log('loglog');
    this.seconds = 5;
    this.mainloading = true;
    this.replay = true;
    // this.watchMovie = true;
    this.videoFinish = false;
    clearInterval(this.secondInterval);
    clearTimeout(this.secondsTimeout);
    this.getVideoDetails(this.videoPath, this.currentIndex);
  }

  adjustPlayer() {
    const container = $('.player-container');

    /* extract constants for better readabilty */
    const lowerEdge = container.height();
    const switchToMinPlayerPos = lowerEdge - (window.innerHeight / 16);
    const currentScrollPos = document.body.scrollTop || document.documentElement.scrollTop;

    /* toggle the css-class responsible for the player moving to the lower right corner */
    if (currentScrollPos > switchToMinPlayerPos) {
      $('.player-switch').addClass('fixed-player');
      if (document.getElementById("pip-close")) {
        document.getElementById("pip-close").onclick = function () { closePip() };
      }
    } else {
      $('.player-switch').removeClass('fixed-player');
    }
    function closePip() {
      window.scroll(0, 0)
    }
  }

  public getCastCrew(assetpath: string): void {
    const pageRequest = { "assetId": assetpath };
    let fiterString = "?filter=" + encodeURI(JSON.stringify(pageRequest));
    this.videoService.getCastCrews(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.castCrewData = res.data;
          this.cast = '';
          this.director = '';
          if (this.castCrewData !== null && this.castCrewData.data) {
            for (const castcrew of this.castCrewData.data) {
              if (castcrew.character[0].professionType == 'Director') {
                if (this.director === '') {
                  this.director = ' ' + this.director + castcrew.firstName;
                } else {
                  this.director = this.director + ', ' + castcrew.firstName;
                }

              } else if (castcrew.character[0].professionType == 'Cast') {
                if (this.cast === '') {
                  this.cast = ' ' + this.cast + castcrew.firstName;
                } else {
                  this.cast = this.cast + ', ' + castcrew.firstName;
                }
              }
            }
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public addTofavourite(path: string): void {
    let userId: any = this.getUserId();
    if (userId > 0) {
      if (this.geoStatus) {
        // const pageRequest = {
        //   //user_id: this.getUserId(),
        //   asset_id: path
        // };
        const pageRequest = {
          assetId: path
        }

        this.videoService.addTofavourite(pageRequest).subscribe(
          res => {
            if (res !== undefined && res.success === true) {
              // this.videopageData.isUserLikes = true;
              this.isUserLikes = 1;
              this.successResponse(res.message);
              //this.successMessageResponse();
              //this.successMessageResponse('remove to fav');
            } else if (res !== undefined && res.success === false) {
              this.errMessageResponse(res.message);
            }
          },
          err => {
            console.log(err);
          }
        );

      }
    } else {
      this.warningMessageResponse('Please sign in to Add to Favourite');
    }
  }

  public removeTofavourite(path): void {
    let userId: any = this.getUserId();
    if (userId > 0) {
      if (this.geoStatus) {
        const pageRequest = {
          assetId: path
        };

        this.videoService.removeTofavourite(pageRequest).subscribe(
          res => {
            if (res !== undefined && res.success === true) {
              this.isUserLikes = 0;
              this.successResponse(res.message);
            }
          },
          err => {
            console.log(err);
          }
        );
      }

    }

  }

  public addToWatchlist(): void {
    const pageRequest = {
      assetId: this.videoPageData.path
    };
    // let filterString = "?filter="+encodeURI(JSON.stringify(pageRequest));
    this.videoService.addToWatchlist(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          // this.videopageData.watchedCount += 1;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public getGenreTitle(videopageData): string {
    var genreTitle = '';

    if (videopageData.genre.length > 0) {
      this.noGenre = true;
    }
    if (videopageData.genre) {
      for (const genre of videopageData.genre) {
        if (genre.length > 0) {
          for (const orientation of genre) {
            if (genreTitle !== '') {
              genreTitle += ', ';
            }
            genreTitle += orientation.path;
          }
        }
      }
      return genreTitle;
    }


  }

  public goToHomePage(): void {
    this.headerService.reloadHeader.emit(true);
    this.router.navigate(['']);
  }

  ngOnDestroy(): void {
    clearTimeout(this.secondsTimeout);
    clearInterval(this.secondInterval);
    if (
      BaseComponent.baseUser !== undefined &&
      Object.keys(BaseComponent.baseUser).length !== 0 && this.playerObject !== undefined
    ) {
      const pageRequest = {
        assetId: this.videoPageData.path,
        duration: this.playerObject.getCurrentTime
      };
      this.videoService.addToBookmark(pageRequest).subscribe(result => res => {
        if (res !== undefined && res.success == true) {
        }
      },
        err => {
          console.log(err);
        }
      );
    }
    if (this.playerObject !== undefined) {
      this.playerObject.pause();
      this.playerObject.unload();
    }
  }

  public gotToLoginPage(): void {
    this.warningMessageResponse('Please sign in to watch Video');
    this.router.navigate(['auth']);
  }

  public facebookShare(e) {
    e.preventDefault();
    var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, 'facebook-popup', 'height=350,width=600');
    if (facebookWindow.focus) {
      facebookWindow.focus();

    }
    return false;
  }

  public twitterShare(e) {
    e.preventDefault();
    var twitterWindow = window.open('https://twitter.com/share?url=' + window.location.href, 'twitter-popup', 'height=350,width=600');
    if (twitterWindow.focus) { twitterWindow.focus(); }
    return false;
  }
}
