import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '../component/search.component';
import { SearchService } from '../services/search.service';
import { CommonService } from 'src/app/shared';



@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule
  ], exports: [SearchComponent],
  providers: [SearchService,CommonService]
})
export class SearchModule { }
