export class GetUserProfile {
    uid: string;
}

export class GetProfileResponse {
//     status: boolean;
//     uname: any;
//     full_name: string;
//     first_name: string;
//     last_name: StorageManager;
//     email: any;
//     about: string;
//     gender: string;
//     mobile: string;
//     picture: string;
//     dob: any;
//     country: string;
//     state: string;
//   success: boolean;
//   data: any;
    success: boolean
    message: string
    data: ProfileData[]
}
export class ProfileData {
    status: boolean;
    uname: any;
    full_name: string;
    first_name: string;
    last_name: StorageManager;
    email: any;
    about: string;
    gender: string;
    mobile: string;
    picture: string;
    //dob: any;
    country: string;
    state: string;
  success: boolean;
  data: any;
  dateOfBirth: any;
  filePath: string;
  firstName: any;
  lastName: any;
  emailId: any;
}

export class ChangePassword {
    email_id: any;
    password: any;
    new_password: any;
}

export class ChangePasswordResponse {
    message(message: any) {
      throw new Error('Method not implemented.');
    }
    status: boolean;
    user_id: string;
    msg: string;
  success: boolean;
}

export class AllData {
    path: string;
    type: string;
    created: string;
    alias: string;
    asset_group_classification: AssetGroupClassification[];
    duration: string;
    air_date: string;
    end_date: string;
    average_rating: number;
    likes_count: number;
    watched_count: number;
    genre: DataGenre[];
    device: string;
    same_for_all_orientation: boolean;
    asset_is_premium: boolean;
    display_indexing: string;
    license_config: [];
    orientation: DataOrientation[];
    order_by: string;
    sorting: string;
    user_can_see_asset: boolean;
}

export class AssetGroupClassification {
    path: string;
    name: string;
}

export class DataGenre {
    path: string;
    type: string;
    alias: string;
    same_for_all_geo_zone: boolean;
    exclusion_text: string;
    same_for_all_device_type: boolean;
    device: string;
    same_for_all_orientation: boolean;
    orientation: DataOrientation[];
    order_by: string;
}

export class DataOrientation {
    description: string;
    image: string;
    image_horizontal: string;
    is_visible: boolean;
    label: string;
    name: string;
    title: string;
}

export class GetWatchlistData {
    status: boolean;
    data: AllData[];
}

export class GetfavouriteData {
    status: boolean;
    data: AllData[];
}

