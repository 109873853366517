export const environment = {
  production: false,
  imagePrefix: 'assets/images/',
    //apiUrl: 'https://84lgli7ca8.execute-api.us-east-1.amazonaws.com/stage/',
    apiUrl: 'https://gyyk2nl8r0.execute-api.us-east-1.amazonaws.com/stage/',
 
  apiUrlDollywood: 'https://dollywoodplay.admin.demo.dctinc.net/api/v1/',
  apiUrlCarousal: 'https://hungamakids.admin.stage.dctinc.net/api/v1/',
  // googleAuthKey: '247746380086-fbrglglfl6lvcn575u82248khpbef1j2.apps.googleusercontent.com', //prod
  googleAuthKey:
    "695989545489-ehsia82b9l31723f82dfilfm9rtfbo41.apps.googleusercontent.com", //dev
  // facebookAuthId: "2473374139418803", //prod
  facebookAuthId: "2239905339471156", // dev
  stripeKey:"pk_test_9EjrHRgtqlBAKLuFA6oLRMU6",
  firebaseConfig: {
    apiKey: "AIzaSyBYbo8txg9JLPyknXEejcPMDVhE9Ldi67g",
    authDomain: "irishrep-df026.firebaseapp.com",
    databaseURL:"https://irishrep-df026-default-rtdb.firebaseio.com/",
    projectId: "irishrep-df026",
    storageBucket: "irishrep-df026.appspot.com",
    messagingSenderId: "82107294507",
    appId: "1:82107294507:web:129ae08a15967ccd235b6e",
    measurementId: "G-KDFWJJ80LY"
  },
};
