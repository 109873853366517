import { Component, OnInit } from '@angular/core';
import { FooterService } from '@footer/services/footer.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { BaseComponent } from '@base/component/base.component';
import { ActivatedRoute, Params } from '@angular/router';
import { Genredetail, GenreList } from '@footer/model/footer.model';
declare var $: any;
@Component({
  selector: 'app-footergenre',
  templateUrl: '../view/footergenre.component.html',
  styleUrls: ['../css/footergenre.component.css']
})
export class FootergenreComponent extends BaseComponent implements OnInit {

  footerService: FooterService;
  footerGenreData: Genredetail;
  genrePath: string;
  viewmore: boolean;
  skip: number;
  loading: boolean;

  constructor(activateRoute: ActivatedRoute) {
    super();
    this.footerService = ServiceLocator.injector.get(FooterService);
    this.route = activateRoute;
  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      $(document).ready(function () {
        $(this).scrollTop(0);
      });
      this.loading = false;
      window.scroll(0, 0);
      this.genrePath = params['path'];
      this.footerGenreData = null;
      this.skip = 0;
      this.getFooterGeners(0, this.genrePath, false);
    });
  }

  public getFooterGeners(skip: number, path: string, setData: boolean): void {
    const genreDetailRequest = {"genreId":path,"deviceTypeId":"1"};
    if (setData) {
      this.skip = this.skip + skip;
      this.loading = true;
    } else {
      this.skip = 0;
    }
    let genreFiterString = "?filter="+encodeURI(JSON.stringify(genreDetailRequest))+"&start="+this.skip+"&limit="+24 
    this.footerService.getFooterVideos(genreFiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          console.log(res);
          if (res.data[0].data != undefined && res.data[0].data.length === 24) {
            this.viewmore = true;
          } else {
            this.viewmore = false;
          }
          if (setData) {
            if(Object.keys(res.data[0].data)){
              res.data[0].data.forEach(record => {
                this.footerGenreData.data.push(record);
              });
            }
            this.loading = false;
          } else {
            this.footerGenreData = res.data[0];
            this.loading = false;
          }

        }

      },
      err => {
        this.loading = false;
        console.log(err);
      }
    );

  }

  public gotoVideoPage(footerGenre: GenreList): void {
    if (footerGenre.type === 'asset') {
      // this.router.navigate(['/video-page'], {
      //   queryParams: { path: footerGenre.path }
      // });
      this.router.navigate(['/video', footerGenre.title, footerGenre.path]);
    } else if (footerGenre.type === 'asset_group') {
      this.router.navigate(['/season', footerGenre.path]);
    }
  }
}

