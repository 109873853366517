import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { FooterService } from '@footer/services/footer.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { FooterModule } from '@footer/module/footer.module';
import { FooterData } from '@footer/model/footer.model';
import { FormGroup, FormControl, Validators, PatternValidator } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: '../view/footer.component.html',
  styleUrls: ['../css/footer.component.css']
})

export class FooterComponent extends BaseComponent implements OnInit {

  footerService: FooterService;
  footerData: FooterData;
  footerLinksData: any;
  footerLinks: any;
  is_loading: boolean;
  year: any = new Date().getFullYear();

  public contactUsForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    mobile: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10) , Validators.pattern("^[0-9]*$")]),
    message: new FormControl('', [Validators.required])
  });

  constructor() {
    super();
    this.footerService = ServiceLocator.injector.get(FooterService);
  }

  ngOnInit() {
    setTimeout(() => {
      this.getFooterGeners();
      this.getFooterLinksData();
    }, 2000);

  }

  public getFooterGeners(): void {
    // const pageRequest = {
    //   device_type: 'WEB',
    // };
    this.footerService.getFooterGenres().subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.footerData = res;
        }
      },
      err => {
        console.log(err);
      }
    );

  }

  public sendContact(contactform: FormGroup): void {
    this.is_loading = true;
    const pageRequest = {
      fullName: contactform.value['name'],
      email: contactform.value['email'],
      mobile: contactform.value['mobile'],
      message: contactform.value['massage']
    };
    this.footerService.sendContactUs(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.successResponse(res.message);
          this.contactUsForm.reset();
          $('#contact-form').modal('hide');
          this.is_loading = false;
        } else if (res !== undefined && res.success == false) {
          this.errMessageResponse(res.message);
          this.is_loading = false;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public getFooterLinksData(): void {
    // const pageRequest = {
    //   device_type: '2',
    // };
    this.footerService.getFooterLinks().subscribe(
      res => {
        if (res !== undefined) {
          this.footerLinksData = res;
          this.footerLinks = res.data.data;
        }
      },
      err => {
        console.log(err);
      }
    );

  }

  public goToFooterLinkData(footerlink: string, path: string): void {
    // if (footerlink === 'About Us') {
      // this.router.navigate(['about-us', path]);
      window.open(window.location.origin + '/footer' + '/'+ footerlink.split(" ").join("") +'/' + path, '_blank');
    // } 
    // else if (footerlink === 'Terms Of Use') {
    //   // this.router.navigate(['terms-of-use', path]);
    //   window.open(window.location.origin + '/terms-of-use/' + path, '_blank');
    // } else if (footerlink === 'Privacy Policy') {
    //   window.open(window.location.origin + '/privacy-policy/' + path, '_blank');
    //   // this.router.navigate(['privacy-policy', path]);
    // }
  }

  public openGenresListing(data): void {
    this.router.navigate(['/category', data.title.split(/\s/).join('').toLowerCase(), data.path]);
  }

}
