import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CommonService {
	constructor() {

	}
	checkIsAfter(startDate) {
	let currentDate = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss'));
	let localStartDate = moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss');
	// let localEndDate = moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss');
	//console.log(localStartDate,localEndDate);
	if (moment(currentDate).isAfter(localStartDate)) {
		return true;
	} else {
		return false;
	}
	}
}
