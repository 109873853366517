import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { ProfileService } from '@profile/service/profile.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { GetfavouriteData } from '@profile/model/profile.model';
import { AllData } from '@profile/model/profile.model';
import { VideoPageService } from '@video-page/services/videopage.service';
import { PageData } from '@home/model/pagecategory.model';

@Component({
  selector: 'app-favorites',
  templateUrl: '../view/favorites.component.html',
  styleUrls: ['../css/favorites.component.css']
})
export class FavoritesComponent extends BaseComponent implements OnInit {

  public profileService: ProfileService;
  public videopageService: VideoPageService;
  favouriteData: GetfavouriteData;
  removeTofavouriteData: AllData;
  loading: boolean;

  constructor(private rendrer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
    super();
    this.profileService = ServiceLocator.injector.get(ProfileService);
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
  }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');

    window.scroll(0, 0);
    this.getfavourit();
  }

  public getfavourit(): void {
    this.loading = true;
    // const requestFavouriteData = {
    //   "userId": this.getUserId()
    // };
    // let fiterFavouriteString = "?filter="+encodeURI(JSON.stringify(requestFavouriteData)); 
    this.profileService.getfavourite().subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.favouriteData = res.data;
          this.loading = false;
        } else if(res !== undefined && res.success === false) {
          this.loading = false
        }
      },
      err => {
        console.log(err);
        this.loading = false;
      }
    );
  }

  public removeTofavourite(assetItem: AllData): void {
    let index: any;
    const pageRequest = {
      assetId: assetItem.path
    };
    this.videopageService.removeTofavourite(pageRequest).subscribe(
      res => {
        if (res !== undefined) {
          this.successResponse(res.message);
          index = this.favouriteData.data.indexOf(assetItem);
          this.favouriteData.data.splice(index, 1);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  // public removeTofavourite(assetItem: AllData): void {
  //   this.loading = true;
  //   let index: any;
  //   const pageRequest = {
  //     user_id: this.getUserId(),
  //     asset_id: assetItem.path
  //   };
  //   this.videopageService.removeTofavourite(pageRequest).subscribe(
  //     res => {
  //       if (res !== undefined) {
  //         this.successResponse(res.msg);
  //         index = this.favouriteData.data.indexOf(assetItem);
  //         this.favouriteData.data.splice(index, 1);
  //         this.loading = false;
  //       }
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  // }

  public setremoveTofavouriteData(setdata: AllData): void {
    this.removeTofavouriteData = setdata;
  }
  public gotoVideoPage(pagedata: PageData): void {
    if (pagedata.type === 'asset') {
      this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
    } else if (pagedata.type === 'asset_group') {
      this.router.navigate(['/season', pagedata.path]);
    }
  }
}
