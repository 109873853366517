import { DOCUMENT } from "@angular/common";
import {
  Component,
  OnInit,
  NgZone,
  SimpleChanges,
  OnDestroy,
  Renderer2,
  inject,
  Inject,
} from "@angular/core";
import { BaseComponent } from "@base/component/base.component";
import { NavigationUpdate, NavigationData } from "@header/model/header.model";
import {
  PageCategoryData,
  PageCategory,
  PageData,
  AssetGroupClassification,
} from "@home/model/pagecategory.model";
import { VideoPage, VideoPageData } from "@video-page/model/video-page";
import { HeaderService } from "@header/services/header.service";
import { HomeService } from "@home/services/home.service";
import { Carousal, CurousalResponse, carouselData } from "@home/model/carousal";
import { ServiceLocator } from "@base/constants/service-locator";
import { Content } from "@angular/compiler/src/render3/r3_ast";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { VideoPageService } from "@video-page/services/videopage.service";
import { Params, Router, ActivatedRoute } from "@angular/router";
import * as moment from 'moment';

declare var $: any;
declare const bitmovin: any;

@Component({
  selector: "app-home",
  templateUrl: "../view/home.component.html",
  styleUrls: ["../css/home.component.css"],
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  public carousalResponse: carouselData[] = [];
  public caroselVideoResponse: carouselData[] = [];
  videopageData: VideoPageData;
  globalData: any;
  showImage = false;
  videoImage = "";
  currentPage: any;
  carouselLength: any;
  constructor(
    private ngZone: NgZone,
    _activatedRoute: ActivatedRoute,
    private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.homeService = ServiceLocator.injector.get(HomeService);
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
    this.route = _activatedRoute;
  }

  public contactform: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    mobile_number: new FormControl("", [Validators.required]),
  });

  public PageCategoryParams = new URLSearchParams();

  pageCategoryData: PageCategoryData;
  headerService: HeaderService;
  public homeService: HomeService;
  public pageData: PageCategoryData;
  public navigation: string;
  public selectedGenreMap: {} = {};
  pagelist: PageData[];
  videopageService: VideoPageService;
  navigationData: NavigationData;
  public NavigationParams = new URLSearchParams();
  // initialLoadItems: number = 2;
  // skippedItems: number = 0;
  // pageCategoriesLoading: boolean;
  skip: number;
  params: any;
  videoSection: boolean;
  showHomeVideo: boolean;
  isPageCategory = false;
  onInitloader = true;
  title: any;

  ngOnInit() {
    // this.initialLoadItems = 2;
    // this.skippedItems = 0;
    // this.pageCategoriesLoading = false;
    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() == $(document).height()) {
      }
    });
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    $(document).ready(function () {
      $("#lightSlider").lightSlider({
        gallery: true,
        item: 1,
        loop: false,
        slideMargin: 0,
        thumbItem: 24,
      });
    });
    this.sliderScript();
    // this.headerService.setHomePagePath.subscribe(res => {
    //   if (res !== undefined) {
    //     window.scroll(0, 0);
    //     console.log(res);
    //     console.log('=-------------------------------------==============');
    //     this.getPageCategorie(res);
    //     this.getCarousalResponse(res['path'], res['type']);
    //   } else {
    //     console.log('Error');
    //   }
    // });
    this.route.params.subscribe((params: Params) => {
      this.onInitloader = true;
      this.caroselVideoResponse = [];
      this.pageCategoryData = null;
      this.carousalResponse = null;
      this.showImage = false;
      this.videoImage = "";
      this.currentPage = params.name;
      this.rendrer.removeClass(this.document.body, "transperent-layout");
      $(document).ready(function () {
        $(this).scrollTop(0);
        window.scroll(0, 0);
      });
      this.navigationData = null;
      const filterNavigation: NavigationData = new NavigationData();

      if (
        BaseComponent.globalNavigationData === undefined ||
        Object.keys(BaseComponent.globalNavigationData).length === 0
      ) {
        const pageRequest = {
          deviceTypeId: 1,
          langaugeCode: 1,
        };
        this.headerService.getNavigation(pageRequest).subscribe((result) => {
          if (result !== undefined && result.success == true) {
            this.navigationData = result.data;
            filterNavigation.data = this.navigationData.data.filter(
              (res) =>
                res.title.split(" ").join("").toLocaleLowerCase() ==
                params.name.split(" ").join("").toLocaleLowerCase()
            );
            this.showHomeVideo = false;
            this.setNavigationData(filterNavigation);
            return true;
          } else if (result !== undefined && result.success == false) {
          }
        });
      } else if (
        BaseComponent.globalNavigationData !== undefined &&
        Object.keys(BaseComponent.globalNavigationData).length !== 0
      ) {
        filterNavigation.data = BaseComponent.globalNavigationData.data.filter(
          (res) =>
            res.title.split(" ").join("").toLocaleLowerCase() ==
            params.name.split(" ").join("").toLocaleLowerCase()
        );
        this.caroselVideoResponse = [];
        this.carousalResponse = null;
        this.showHomeVideo = false;
        this.setNavigationData(filterNavigation);
        return true;
      }
    });
  }

  public setNavigationData(navigate: NavigationData): void {
    this.title = navigate.data[0].title;

    if (navigate !== undefined) {
      const requestParam = {
        path: navigate.data[0].path,
        type: navigate.data[0].type,
      };
      this.params = requestParam;
      this.skip = 0;
      this.getPageCategorie(requestParam, false, 0);
      this.getCarousalResponse(requestParam);
      this.headerService.setActivePath.emit(navigate.data[0].title);
    }
  }

  refreshListingTooltip(): void {
    // $(".box-container-1").tooltipster({
    //   interactive: true,
    //   maxWidth: 267
    // });
  }
  public getCarousalResponse(requestParam): void {
    this.carousalResponse = null;
    this.caroselVideoResponse = null;
    const carousal = new Carousal();
    // carousal.device_type = "2";
    // carousal.path = path;
    // carousal.type = type;

    if (requestParam !== undefined) {
      this.navigation = requestParam;
    }

    const carouselRequest = {
      path: this.navigation["path"],
      type: this.navigation["type"],
      deviceTypeId: "1",
    };
    // this.pageCategoryData = null;
    let fiterString = "?filter=" + encodeURI(JSON.stringify(carouselRequest));

    this.homeService.getMainCarousal(fiterString).subscribe(
      (res) => {
        if (res !== undefined && res.success === true) {
          this.carousalResponse = res.data.filter(
            (data) =>
              data.carouselType == "Image" || data.carouselType == "Video"
          );
          this.caroselVideoResponse = res.data.filter(
            (data) => data.carouselType == "Video"
          );
          if (this.caroselVideoResponse.length > 0) {
            this.carouselLength = true;
            this.showImage = true;
            this.videoImage = this.caroselVideoResponse[0].filePath;
            window.scroll(0, 0);
            this.onInitloader = false;
            this.shortCarouselVideo(this.caroselVideoResponse[0]);
          }
          if (this.caroselVideoResponse.length == 0) {
            this.ngZone.run(() => {
              setTimeout(() => {
                this.sliderScript();
                $("#lightSlider").lightSlider({
                  gallery: true,
                  locator: true,
                  item: 1,
                  loop: false,
                  slideMargin: 0,
                  thumbItem: 24,
                });
              }, 500);
            });
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getParallaxImage(image: string): string {
    return image;
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   let log: string[] = [];

  //   if (changes['catPath']) {
  //       if (changes['catPath'].currentValue !== undefined)
  //       {
  //           this.initialLoadItems = 2;
  //           this.skippedItems = 0;
  //           this.pageCategoryData = [];
  //           this.getPageCategorie(this.params);

  //       }
  //     }
  //   }
  refreshHorizontalCarousel(pageCategoryId: string): void {
    $("#page_category_list_" + pageCategoryId).owlCarousel({
      loop: false,
      margin: 21,
      nav: true,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">',
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
        },
        768: {
          items: 4,
        },
        1000: {
          items: 5,
        },
      },
    });
  }

  refreshVerticalCarousel(pageCategoryId: string): void {
    $("#page_category_list_" + pageCategoryId).owlCarousel({
      loop: false,
      margin: 13,
      nav: true,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">',
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
        },
        768: {
          items: 3,
        },
        1000: {
          items: 6,
        },
      },
    });
  }

  refreshCustomCarousel(pageCategoryId: string): void {
    $("#page_category_list_" + pageCategoryId).owlCarousel({
      loop: false,
      margin: 15,
      nav: true,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">',
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 3,
          nav: false,
        },
        768: {
          items: 5,
        },
        1000: {
          items: 6,
        },
      },
    });
  }

  refreshListingCarousel(pageCategoryId: string): void {
    $("#page_category_listing_" + pageCategoryId).owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">',
      ],
      rtl: false,
      autoplay: false,
      autoHeight: false,
      autoWidth: true,
      responsive: {
        0: {
          nav: false,
          rtl: false,
        },
        768: {
          items: 3,
        },
        1000: {
          items: 6,
        },
      },
    });
  }

  refreshSubCategoryCarousel(pageCategoryId: string): void {
    $("#page_subcategory_" + pageCategoryId).owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      navText: [
        '<img src="./assets/images/prev-arrow.svg">',
        '<img src="./assets/images/next-arrow.svg">',
      ],
      rtl: false,
      autoplay: false,
      autoHeight: false,
      autoWidth: true,
      responsive: {
        0: {
          nav: false,
          rtl: false,
        },
        768: {
          items: 3,
        },
        1000: {
          items: 3,
        },
      },
    });
  }

  public getPageCategorie(
    navigation: {},
    IsScrrol: boolean,
    skip: number
  ): void {
    if (navigation !== undefined) {
      this.navigation = navigation["path"];
    }

    const pageCategoryRequest = {
      deviceTypeId: "1",
      path: navigation["path"],
      type: navigation["type"],
    };
    this.skip = this.skip + skip;
    // this.pageCategoryData = null;
    let fiterString =
      "?filter=" +
      encodeURI(JSON.stringify(pageCategoryRequest)) +
      "&start=" +
      this.skip +
      "&limit=" +
      2 +
      "&showCount=" +
      7;
    this.homeService.getPageCategories(fiterString).subscribe(
      (res) => {
        if (res !== undefined && res.success == true) {
          const pageCategory: PageCategoryData = new PageCategoryData();
          if (IsScrrol) {
            if (res.data.length > 0) {
              res.data.forEach((record) => {
                pageCategory.data = this.pageCategoryData.data.filter(
                  (content) => content.path == record.path
                );
              });
              if (pageCategory.data.length === 0) {
                res.data.forEach((record, index) => {
                  this.pageCategoryData.data.push(record);
                  // pageCategory.data[0].is_loading = true;
                  if (record.genre.length > 1) {
                    this.getDefualtPageList(record.path);
                  } else {
                    const pageCategory: PageCategoryData = new PageCategoryData();
                    pageCategory.data = this.pageCategoryData.data.filter(
                      (content) => content.path === record.path
                    );
                    pageCategory.data[0].data = res.data[index].data;
                    pageCategory.data[0].isGenrePath = 0;
                    // this.isPageCategory = true;
                    pageCategory.data[0].isPageCategory = true;
                    if (pageCategory.data[0].data.length > 1) {
                      pageCategory.data[0].isDisplay = true;
                    } else {
                      pageCategory.data[0].isDisplay = true;
                    }
                    // pageCategory.data[0].is_loading = false;
                    $(
                      "#page_category_list_" + pageCategory.data[0].path
                    ).trigger("destroy.owl.carousel");

                    setTimeout(() => {
                      if (
                        pageCategory.data[0].genre.length != 0 ||
                        pageCategory.data[0].assetGroupClassification.length !=
                        0
                      ) {
                        this.refreshSubCategoryCarousel(
                          pageCategory.data[0].path
                        );
                      }

                      if (
                        pageCategory.data[0].displayType == "Horizontal" &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshHorizontalCarousel(
                          pageCategory.data[0].path
                        );
                      } else if (
                        pageCategory.data[0].displayType == "Vertical" &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshVerticalCarousel(pageCategory.data[0].path);
                      } else if (
                        pageCategory.data[0].displayType == "Custom" &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshCustomCarousel(pageCategory.data[0].path);
                      } else if (
                        pageCategory.data[0].displayType == "Listing" &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshListingCarousel(pageCategory.data[0].path);
                        this.refreshListingTooltip();
                      }
                    }, 50);
                  }
                });
              }
            }
          } else {
            this.pageCategoryData = res;
            // pageCategory.data[0].is_loading = true;
            this.pageCategoryData.data.forEach((pagelist, index) => {
              if (pagelist.genre.length > 1) {
                this.getDefualtPageList(pagelist.path);
              } else {
                const pageCategory: PageCategoryData = new PageCategoryData();
                pageCategory.data = this.pageCategoryData.data.filter(
                  (content) => content.path === pagelist.path
                );

                pageCategory.data[0].is_loading = true;
                pageCategory.data[0].data = pagelist.data;
                pageCategory.data[0].isGenrePath = 0;
                pageCategory.data[0].is_loading = false;
                // this.isPageCategory = true;
                pageCategory.data[0].isPageCategory = true;
                if (pageCategory.data[0].data.length > 1) {
                  pageCategory.data[0].isDisplay = true;
                } else {
                  pageCategory.data[0].isDisplay = true;
                }
                $("#page_category_list_" + pageCategory.data[0].path).trigger(
                  "destroy.owl.carousel"
                );

                setTimeout(() => {
                  if (
                    pageCategory.data[0].genre.length != 0 ||
                    pageCategory.data[0].assetGroupClassification.length != 0
                  ) {
                    this.refreshSubCategoryCarousel(pageCategory.data[0].path);
                  }

                  if (
                    pageCategory.data[0].displayType == "Horizontal" &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshHorizontalCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == "Vertical" &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshVerticalCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == "Custom" &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshCustomCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == "Listing" &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshListingCarousel(pageCategory.data[0].path);
                    this.refreshListingTooltip();
                  }
                }, 50);
              }
            });
          }
        } else if (res !== undefined && !res.success) {
          console.log(res);
        }
        // this.pageCategoriesLoading = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public gotoPremiumVideo(): void {
    this.router.navigate(["premium"]);
  }

  public getDefualtPageList(path: string): void {
    const pageListRequest = { deviceTypeId: "1", path: path };

    $("#page_subcategory_" + path + " li a").removeClass("active");

    $("#page_subcategory_item_" + path + " a").addClass("active");
    const pageCategory: PageCategoryData = new PageCategoryData();
    pageCategory.data = this.pageCategoryData.data.filter(
      (content) => content.path === path
    );
    pageCategory.data[0].is_loading = true;
    let fiterString =
      "?filter=" +
      encodeURI(JSON.stringify(pageListRequest)) +
      "&start=0&limit=7";
    this.homeService.getDefualtPageList(fiterString).subscribe(
      (res) => {
        if (res !== undefined && res.success === true) {
          pageCategory.data = this.pageCategoryData.data.filter(
            (content) => content.path == path
          );
          pageCategory.data[0].isGenrePath = 0;
          pageCategory.data[0].data = res.data[0].data;
          // this.isPageCategory = true;
          pageCategory.data[0].isPageCategory = true;
          if (pageCategory.data[0].data.length > 1) {
            pageCategory.data[0].isDisplay = true;
          } else {
            pageCategory.data[0].isDisplay = true;
          }
          console.log("pageCategory.data[0].data", pageCategory.data[0].data);
          pageCategory.data[0].is_loading = false;
          $("#page_category_list_" + pageCategory.data[0].path).trigger(
            "destroy.owl.carousel"
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length != 0 ||
              pageCategory.data[0].assetGroupClassification.length != 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType == "Horizontal" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Vertical" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Listing" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // isGenerClicked(){
  //   if(Object.keys(this.selectedGenreMap).length > 0){
  //     return true;
  //   }
  //   return false;
  // }
  public getGenrePageList(genrepath: string, path: string): void {
    // this.selectedGenreMap['path'] = genrepath;
    // console.log("this.selectedGenreMap[path]", this.selectedGenreMap)
    const genredataRequest = {
      path: path,
      deviceTypeId: "1",
      genre: [genrepath],
    };
    let genreFiterString =
      "?filter=" +
      encodeURI(JSON.stringify(genredataRequest)) +
      "&start=0&limit=7";

    $("#page_subcategory_" + path + " li a").removeClass("active");

    $("#page_subcategory_item_" + path + "_" + genrepath + " a").addClass(
      "active"
    );

    const pageCategory: PageCategoryData = new PageCategoryData();
    pageCategory.data = this.pageCategoryData.data.filter(
      (content) => content.path == path
    );
    pageCategory.data[0].isGenrePath = genrepath;
    pageCategory.data[0].is_loading = true;

    this.homeService.getDefualtPageList(genreFiterString).subscribe(
      (res) => {
        if (res !== undefined && res.success == true) {
          pageCategory.data[0].isPageCategory = false;
          pageCategory.data[0].data = res.data[0].data;
          pageCategory.data[0].is_loading = false;
          $("#page_category_list_" + pageCategory.data[0].path).trigger(
            "destroy.owl.carousel"
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length !== 0 ||
              pageCategory.data[0].assetGroupClassification.length !== 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType === "Horizontal" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === "Vertical" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === "Listing" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public getAssetGroupList(
    assetpath: AssetGroupClassification,
    path: string
  ): void {
    const pageListRequest = {
      deviceTypeId: "1",
      assetGroupClassification: assetpath,
      path: path,
      limit: 7,
    };

    $("#page_subcategory_" + path + " li a").removeClass("active");

    $("#page_subcategory_item_" + path + "_" + assetpath + " a").addClass(
      "active"
    );

    this.homeService.getDefualtPageList(pageListRequest).subscribe(
      (res) => {
        if (res !== undefined && res.success == true) {
          const pageCategory: PageCategoryData = new PageCategoryData();
          pageCategory.data = this.pageCategoryData.data.filter(
            (content) => content.path === path
          );
          pageCategory.data[0].data = res.data[0].data;
          $("#page_category_list_" + pageCategory.data[0].path).trigger(
            "destroy.owl.carousel"
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length != 0 ||
              pageCategory.data[0].assetGroupClassification.length != 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType == "Horizontal" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Vertical" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Listing" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      (err) => {
        console.log("errror");
        console.log(err);
      }
    );
  }

  gotoAsset(asset) {
    if (asset.clickThrough != null && asset.clickThrough != "") {
      window.open(asset.clickThrough, "_blank");
    } else if (asset.type == "asset") {
      const title = asset.title;
      this.router.navigate(["/video", title.replace(/\s/g, ""), asset.path]);
    } else if (asset.type == "asset_group") {
      this.router.navigate(["/season", asset.path]);
    }
  }
  gotoCarouselAsset(asset) {
    this.router.navigate([asset.clickThrough]);
  }

  public gotoVideoPage(pagedata: PageData, carousel: carouselData): void {
    console.log(pagedata);
    if (carousel !== null) {
      if (carousel.type === "asset" && carousel.path !== null) {
        // this.router.navigate(['/video-page'], {
        //   queryParams: { path: contents.path }
        // });
        // pending
        // this.router.navigate([
        //   "/video",
        //   contents..substr(1),
        //   contents.path
        // ]);
        // return '/video-page/' + contents.path;
      } else if (carousel.type === "asset_group") {
        this.router.navigate(["/season", carousel.path]);
        // return '/asseet-group/' + contents.path;
      }
    } else {
      if (pagedata.type === "asset") {
        let currentDate = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss'));
        // let localStartDate = moment.utc(pagedata.airStartDate).format('YYYY-MM-DD HH:mm:ss');
        let localStartDate = moment.utc(pagedata.airStartDate).format('YYYY-MM-DD HH:mm:ss');
        if (moment(currentDate).isAfter(localStartDate)  || pagedata.airStartDate == '0000-00-00 00:00:00' || localStartDate == "Invalid date" || pagedata.contentType == 'Live') {
          // return true;
          this.router.navigate([
            "/video",
            pagedata.title.split(/\s/).join(""),
            pagedata.path,
          ]);
        } else {
          return;
        }
        // return '/video-page/' + pagedata.path;
      } else if (pagedata.type === "asset_group") {
        this.router.navigate(["/season", pagedata.path]);
        // return '/asseet-group/' + pagedata.path;
      }
    }
  }

  public openGenresListing(path: string, genrePath): void {
    const myurl = "/genres";
    let param = {
      navigationpath: this.params.path,
      path: path,
    };
    if (genrePath != 0) {
      // param["genrePath"] = this.selectedGenreMap['path'];
      this.router.navigate([
        myurl,
        param["navigationpath"],
        param["path"],
        genrePath,
      ]);
    } else {
      this.router.navigate([myurl, param["navigationpath"], param["path"]]);
    }

    // this.router.navigate([myurl], {
    //   queryParams: param
    // });
  }

  public bindPageListing(pagedata: PageData[]): PageData[] {
    this.pagelist = null;
    if (pagedata.length !== 0) {
      pagedata.forEach((data, index) => {
        if (index !== 0) {
          if (this.pagelist != null) {
            this.pagelist = this.pagelist.concat(data);
          } else {
            this.pagelist = [];
            this.pagelist = this.pagelist.concat(data);
          }
        }
      });
    }

    return this.pagelist;
  }
  public addTofavourite(
    assetPath: string,
    pagecategory,
    imagenumber,
    index
  ): void {
    console.log(assetPath);
    console.log(pagecategory);
    console.log(index);
    if (
      BaseComponent.baseUser !== undefined &&
      Object.keys(BaseComponent.baseUser).length !== 0
    ) {
      const pageRequest = {
        assetId: assetPath,
      };
      this.videopageService.addTofavourite(pageRequest).subscribe(
        (res) => {
          if (res !== undefined && res.success == true) {
            this.successResponse(res.message);
            const indexofCategory = this.pageCategoryData.data.findIndex(
              (categoryIndex) => {
                return categoryIndex.path == pagecategory.path;
              }
            );
            if (imagenumber == 1) {
              return (this.pageCategoryData.data[
                indexofCategory
              ].data[0].isUserLikes = 1);
            } else if (imagenumber == 6) {
              let indexData = index + 1;
              return (this.pageCategoryData.data[indexofCategory].data[
                indexData
              ].isUserLikes = 1);
            }
            // pageCategory.data[0].is_loading = true;
            // return;
          } else if (res !== undefined && res.success == false) {
            this.errMessageResponse(res.message);
            // return;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.warningMessageResponse("Please Login To Add To Favorites");
    }
  }

  public removeTofavourite(
    assetPath: string,
    pagecategory,
    imagenumber,
    index
  ): void {
    console.log(assetPath);
    console.log(pagecategory);
    console.log(index);
    if (
      BaseComponent.baseUser !== undefined &&
      Object.keys(BaseComponent.baseUser).length !== 0
    ) {
      const pageRequest = {
        assetId: assetPath,
      };
      this.videopageService.removeTofavourite(pageRequest).subscribe(
        (res) => {
          if (res !== undefined && res.success == true) {
            this.successResponse(res.message);
            const indexofCategory = this.pageCategoryData.data.findIndex(
              (categoryIndex) => {
                return categoryIndex.path == pagecategory.path;
              }
            );
            if (imagenumber == 1) {
              return (this.pageCategoryData.data[
                indexofCategory
              ].data[0].isUserLikes = 0);
            } else if (imagenumber == 6) {
              let indexData = index + 1;
              return (this.pageCategoryData.data[indexofCategory].data[
                indexData
              ].isUserLikes = 0);
            }
          } else if (res !== undefined && res.success == false) {
            this.errMessageResponse(res.message);
            // return;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.warningMessageResponse("Please Login To Add To Favorites");
    }
  }

  onScroll() {
    if (this.params !== undefined) {
      this.getPageCategorie(this.params, true, 2);
    }

    // this.pageCategoriesLoading = true;
    // this.skippedItems = this.skippedItems + this.initialLoadItems;
    // this.getPageCategorie(this.params);
  }

  shortCarouselVideo(caroselVideoResponse) {
    var self = this;
    this.showHomeVideo = true;
    // this.route.params.subscribe((params: Params) => {
    this.videopageData = null;
    // if (params.name.toLocaleLowerCase() == "home") {
    var carouselVideoPath = 0;
    if (
      caroselVideoResponse.confTrailerVideo != "" &&
      caroselVideoResponse.confTrailerVideo != null
    ) {
      carouselVideoPath = caroselVideoResponse.confTrailerVideo;
    } else {
      carouselVideoPath = caroselVideoResponse.path;
    }
    const assetDetailRequest = { deviceTypeId: "1" };
    const assetDetailFiterString =
      "?filter=" + encodeURI(JSON.stringify(assetDetailRequest));
    this.videopageService
      .getAssetDetails(carouselVideoPath, assetDetailFiterString)
      .subscribe(
        (res) => {
          if (res !== undefined && res.success === true) {
            this.videopageData = res.data[0];

            if (
              this.videopageData &&
              this.videopageData.playbackUrl &&
              this.videopageData.playbackUrl != null &&
              this.videopageData.playbackUrl != ""
            ) {
              this.videoSection = true;
              this.rendrer.addClass(this.document.body, "transperent-layout");
              setTimeout(() => {
                const source = {
                  hls: self.videopageData.playbackUrl,
                  poster: self.videopageData.horizontalFilePath,
                };
                const config = {
                  key: "e402008a-94c7-4288-912b-2e4ab92d5d14",
                  ui: false,
                  playback: {
                    autoplay: true,
                    muted: true,
                  },
                  analytics: {
                    key: "ac814c2b-40b6-4085-8d3d-073db406b5d2",
                    videoId: caroselVideoResponse.path,
                    title: self.videopageData.title,
                  },
                };
                const container = document.getElementById("bitmovin-player");
                const player = new bitmovin.player.Player(container, config);
                $("#volume-on").hide();
                player.load(source).then(
                  function () {
                    console.log(
                      "Successfully created Bitmovin Player instance"
                    );
                  },
                  function (reason) {
                    console.log(
                      "Error while creating Bitmovin Player instance"
                    );
                  },
                  0.8
                );
                player.on(bitmovin.player.PlayerEvent.Ready, function () {
                  setTimeout(() => {
                    self.showImage = false;
                  }, 1000);
                });
                player.on(
                  bitmovin.player.PlayerEvent.PlaybackFinished,
                  function () {
                    console.log("called");
                    self.showImage = true;
                    // return this.carouselVideo = false;

                    // $('#slider-vod').hide();
                    // // $("#vod_sl").fadeOut();
                    // $('#slider_cont').removeClass('hide-slide');

                    // $('#slider_cont').addClass('show-slide');
                    // $('#vod_cont').removeClass('show-slide');
                    // $('#vod_cont').addClass('hide-slide');
                    // $('#lightSlider').lightSlider({
                    //   gallery: true,
                    //   item: 1,
                    //   loop: false,
                    //   slideMargin: 0,
                    //   thumbItem: 24
                    // });
                    // self.sliderScript();

                    // $("#slider-caro").show();

                    // $("#slider-caro").fadeTo("slow", 1, function () {

                    // });

                    // $("#slider_cont").css('opacity', '1');
                  }
                );

                $("#volume-off").click(function () {
                  $("#volume-off").hide();
                  $("#volume-on").show();
                  player.unmute();
                });
                $("#volume-on").click(function () {
                  $("#volume-on").hide();
                  $("#volume-off").show();
                  player.mute();
                });
              });
            } else {
              this.rendrer.removeClass(
                this.document.body,
                "transperent-layout"
              );
              return (this.videopageData = null);
            }
          } else {
            this.rendrer.removeClass(this.document.body, "transperent-layout");
            return (this.videopageData = null);
          }
          //     })
          // }
        },
        (err) => {
          this.rendrer.removeClass(this.document.body, "transperent-layout");
          this.carouselLength = false;
        }
      );
  }

  ngOnDestroy() {
    console.log("ngOnDestroy");
  }

  sliderScript() {
    $(document).ready(function () {
      var sync1 = $("#sync1");
      var sync2 = $("#sync2");
      var slidesPerPage = 6; // globaly define number of elements per page
      var syncedSecondary = true;

      sync1
        .owlCarousel({
          items: 1,
          slideSpeed: 2000,
          nav: false,
          autoplay: true,
          dots: false,
          loop: true,
          responsiveRefreshRate: 200,
          // navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>','<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
        })
        .on("changed.owl.carousel", syncPosition);

      sync2
        .on("initialized.owl.carousel", function () {
          sync2.find(".owl-item").eq(0).addClass("current");
        })
        .owlCarousel({
          items: slidesPerPage,
          dots: false,
          nav: false,
          smartSpeed: 200,
          slideSpeed: 500,
          slideBy: slidesPerPage, // alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
          responsiveRefreshRate: 100,
        })
        .on("changed.owl.carousel", syncPosition2);

      function syncPosition(el) {
        // if you set loop to false, you have to restore this next line
        // var current = el.item.index;

        // if you disable loop you have to comment this block
        var count = el.item.count - 1;
        var current = Math.round(el.item.index - el.item.count / 2 - 0.5);

        if (current < 0) {
          current = count;
        }
        if (current > count) {
          current = 0;
        }

        // end block

        sync2
          .find(".owl-item")
          .removeClass("current")
          .eq(current)
          .addClass("current");
        var onscreen = sync2.find(".owl-item.active").length - 1;
        var start = sync2.find(".owl-item.active").first().index();
        var end = sync2.find(".owl-item.active").last().index();

        if (current > end) {
          sync2.data("owl.carousel").to(current, 100, true);
        }
        if (current < start) {
          sync2.data("owl.carousel").to(current - onscreen, 100, true);
        }
      }

      function syncPosition2(el) {
        if (syncedSecondary) {
          var number = el.item.index;
          sync1.data("owl.carousel").to(number, 100, true);
        }
      }

      sync2.on("click", ".owl-item", function (e) {
        e.preventDefault();
        var number = $(this).index();
        sync1.data("owl.carousel").to(number, 300, true);
      });
    });
  }

  // showCarouselVideo(carousel) {
  //   if(carousel.carouselType == 'Video') {
  //     $("#slider-vod").show();
  //     $("#slider_cont").addClass("hide-slide");
  //     $("#slider_cont").removeClass("show-slide");
  //     $("#vod_cont").addClass("show-slide");
  //     $("#vod_cont").removeClass("hide-slide");
  //     this.shortCarouselVideo(carousel);
  //   }
  // }
}
