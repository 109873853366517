import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPageComponent } from '@video-page/component/video-page.component';
import { VideoPageService } from '@video-page/services/videopage.service';
import { HttpClientModule } from '@angular/common/http';
import { ShareButtonModule } from '@ngx-share/button';

import { VMomentPipe } from '@home/component/pipes/momentjs.pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';


@NgModule({
  declarations: [VideoPageComponent,VMomentPipe],
  imports: [
    CommonModule,
    HttpClientModule,
    ShareButtonModule,
    FormsModule,
    AngularFireDatabaseModule
  ],
  exports: [VideoPageComponent],
  providers: [VideoPageService]
})
export class VideoPageModule { }
