import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetGroupDetailsComponent } from '@asset-detail/component/asset-group-details.component';
import { AssetGroupDetailService } from '@asset-detail/services/asset-group-details.service';
import { HMomentPipe } from '@home/component/pipes/momentjs.pipe';
import { CommonService } from 'src/app/shared';

@NgModule({
  declarations: [AssetGroupDetailsComponent,HMomentPipe],
  imports: [
    CommonModule
  ],
  exports: [AssetGroupDetailsComponent],
  providers: [AssetGroupDetailService, CommonService]
})
export class AssetGroupDetailsModule { }
