import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from '@profile/component/profile.component';
import { ProfileService } from '@profile/service/profile.service';
import { RouterModule } from '@angular/router';
import { BaseModule } from '@base/module/base.module';
import { UtcToLocalDateProfilePipe } from '@profile/pipe/UtcToLocalDateProfilePipe';
import { PMomentPipe } from '@home/component/pipes/momentjs.pipe';
import { CommonService } from 'src/app/shared';

@NgModule({
  declarations: [ProfileComponent, UtcToLocalDateProfilePipe,PMomentPipe],
  imports: [CommonModule, RouterModule, BaseModule],
  exports: [ProfileComponent],
  providers: [ProfileService,CommonService]
})
export class ProfileModule { }
